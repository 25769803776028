






















































































































import { PageBase } from "@/core/models/shared";
import { AlunoTurmaService } from "@/core/services/geral";
import { Component } from "vue-property-decorator";


@Component
export default class RelatorioFinanceiro extends PageBase{

    service: AlunoTurmaService = new AlunoTurmaService();
    lista: any[] = [];
    totalLista: number = 0;
    filtro: any = {
        turmaNome: '',
        layout: 0,
        turmaId: null,
        alunoId: null,
        pago: null,
        vencimentoDataInicio: '',
        vencimentoDataFim: '',
        pagamentoDataInicio: '',
        pagamentoDataFim: ''
    }
    total: number = 0;
    overlay: boolean = false;

    mounted() {

        this.filtro = {
            turmaNome: this.$route.query.turmaNome,
            layout: this.$route.query.layoutId,
            turmaId: this.$route.query.turmaId,
            alunoId: this.$route.query.alunoId,
            pago: this.$route.query.pago,
            vencimentoDataInicio: this.$route.query.vencimentoDataInicio,
            vencimentoDataFim: this.$route.query.vencimentoDataFim,
            pagamentoDataInicio: this.$route.query.pagamentoDataInicio,
            pagamentoDataFim: this.$route.query.pagamentoDataFim,
        }

        if(this.filtro.layout == 0){
            this.Carregar1();
        }
        else if(this.filtro.layout == 1){
            this.Carregar2();
        }
    }

    Carregar1(){
        this.overlay = true;
        this.service.RelatorioFinanceiro(this.filtro.vencimentoDataInicio, this.filtro.vencimentoDataFim, this.filtro.pagamentoDataInicio, this.filtro.pagamentoDataFim, this.filtro.turmaId, this.filtro.alunoId, this.filtro.pago).then(
            res =>{
                this.lista = res.data;
                this.total = 0;
                this.lista.forEach(x => this.total += x.valor)
            },
            err =>{
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        ).finally(() => {
            this.overlay = false;
        })
    }

    Carregar2(){
        
        this.overlay = true;

        const filtro = this.service.MontaFiltroFinanceiro(this.filtro);

        this.service.ListarTudoFiltro(filtro, 'Aluno/nome', "Aluno.Endereco.Municipio.Estado, Aluno.Usuario.Gestor, Aluno.Usuario.Indicador").then(
            res => {
                this.lista = res.data.items;
                this.totalLista = this.lista.length;
                this.lista.forEach(x => this.total += x.valorTotal)
            },
            err =>{
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        ).finally(() => {
            this.overlay = false;
        })
    }
}
